@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.timelineItem {
  display: flex;
}

.timelineTimespan {
  white-space: nowrap;
  padding-right: 2rem;
  margin-top: -0.2rem;
  margin-bottom: 1rem;
}

.timelineTimespanInner {
  display: flex;
  align-items: center;
}

.timelineTimespan > small {
  position: absolute;
}

.timelineTimespan:after {
  content: "XXX 0000 — XXX 0000 - XXX";
  visibility: hidden;
}

.timelineEntry {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timelineEntry h3,
.timelineEntry p {
  margin: 0 0 0.25rem;
  padding: 0;
  line-height: 1.6rem;
}

.timelineEntry h3 {
  display: flex;
  align-items: center;
}

.role {
  color: var(--text-60);
}

.pulser {
  margin-left: 1rem;
  width: 11px;
  height: 11px;
  position: relative;
}

.pulser::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: var(--red);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pulser::after {
  content: '';
  border: 3px solid var(--red);
  border-radius: 30px;
  height: 20px;
  width: 20px;
  animation: pulsate 1s ease-out infinite;
  opacity: 0;
  position: absolute;
  top: calc(-50% - 2.5px);
  left: calc(-50% - 2.5px);
}

.hiring {
  display: inline-block;
  padding-top: 1rem;
  color: var(--red);
  text-decoration-thickness: 1px;
  text-decoration-color: var(--red-40);
}

.hiring:hover {
  text-decoration-color: var(--red);
}

.location {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;
}

.roles {
  display: flex;
  gap: .5rem;
}

.roles > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.roles > ul > li {
  display: block;
  line-height: 1.2rem;
  position: relative;
}

.roles > ul > li:not(:first-child) {
  opacity: .4;
}

@media (max-width: 600px) {
  .timelineItem {
    flex-direction: column-reverse;
  }

  .timelineTimespan {
    margin-bottom: 0;
  }

  .timelineLeft {
    margin-top: 1rem;
  }
}
